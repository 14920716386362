<script setup>
  import 'vue3-carousel/dist/carousel.css'  
  import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel/dist/carousel.es.js'
  import { useWebStore } from '@/store/web.js'
  import { reactive, computed } from 'vue'
  const store = useWebStore();
  const props = defineProps(['slides','noOfSlides','bordered'])

  const slidesToShow = computed(() => {
      if(props.noOfSlides === undefined){
        if(store.screenSize.includes('mobile'))
          return 1.5
        else if(store.screenSize.includes('tablet'))
          return 2.5
        else return 3.5
      }
      return props.noOfSlides
    })

</script>
<template>
  <carousel :items-to-show="slidesToShow" :wrap-around="true" :autoplay="3500" :transition="1000">
    <Slide v-for="slide in slides" :key="slide" :class="[bordered === true? 'border-1px-black':'',]" class="h-8">
      <div class="carousel__item">
        <ImageLazy imgClass="max-w-48 max-h-24" :src="'/'+slide" alt="partner logo"/>
      </div>
    </Slide>

    <template #addons>
      <Navigation />
    </template>
  </Carousel>
</template>

<style scoped lang="scss">
  .carousel__slide{
    height: 260px;
  }
  .carousel__item{
    justify-content: center;
    align-content: center;
  }
  .mobile .carousel__slide,
  .tablet .carousel__slide{
    height: 130px;
  }

  .mobile .carousel__item,
  .tablet .carousel__item{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 130px
  }
  .img{
    max-height: 100px;
    max-width: 90%;
  }
</style>
