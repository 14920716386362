<template>
    <div ref="imageWrapper">
      <img
        v-if="shouldLoad"
        :src="src"
        :alt="alt"
        @load="onLoad"
        :class="[isLoaded ? 'opacity-100' : 'opacity-0', imgClass]"
        class="transition-opacity duration-300"
      />
      <div v-else class="bg-gray-200 animate-pulse" :style="{ aspectRatio: aspect }"></div>
    </div>
  </template>
  
  <script setup>
  import { ref, computed } from 'vue'
  import { useIntersectionObserver } from '~/composables/useIntersectionObserver'
  
  const props = defineProps({
    src: {
      type: String,
      required: true
    },
    alt: {
      type: String,
      default: ''
    },
    aspect: {
      type: String,
      default: '16/9'
    },
    imgClass: {
      type: String,
      default: () => ""
    },
    loadOffset: {
      type: String,
      default: '200px'
    }
  })
  
  const { isNearViewport, isIntersecting, elementRef: imageWrapper } = useIntersectionObserver({
    threshold: 0,
    loadOffset: props.loadOffset,
  })
  
  const isLoaded = ref(false)
  const hasStartedLoading = ref(false)
  
  const shouldLoad = computed(() => {
    if (isLoaded.value || hasStartedLoading.value) return true
    if (isNearViewport.value) {
      hasStartedLoading.value = true
      return true
    }
    return false
  })
  
  const onLoad = () => {
    isLoaded.value = true
  }
  
  // Computed property to determine if we're on the client side
  const isClient = computed(() => process.client)
  </script>
  